/* eslint-disable no-shadow */
import jwtDecode from 'jwt-decode';

import types from '../types';
import lowcoApi from '../../api/lowco-api';

const state = {
  [types.TOKEN]: null,
  [types.REFRESH_TOKEN]: null,
  [types.AUTH_ERROR]: null,
};

const mutations = {
  [types.SIGN_IN]: (state) => {
    state[types.AUTH_ERROR] = null;
  },
  [types.SIGN_IN_SUCCESS]: (state, data) => {
    const { token, refreshToken } = data;

    state[types.TOKEN] = token;
    state[types.REFRESH_TOKEN] = refreshToken;
  },
  [types.SIGN_OUT]: (state) => {
    state[types.TOKEN] = null;
    state[types.REFRESH_TOKEN] = null;
    state[types.AUTH_ERROR] = null;
  },
  [types.SIGN_IN_FAILED]: (state, error) => {
    state[types.AUTH_ERROR] = error;
  },
};

const actions = {
  [types.SIGN_IN_ACTION]: async ({ commit }, credentials) => {
    commit(types.SIGN_IN);

    try {
      const result = await lowcoApi.authenticate(credentials);

      commit(types.SIGN_IN_SUCCESS, result.data);
    } catch (err) {
      commit(types.SIGN_IN_FAILED, err);
      throw err;
    }
  },
  [types.SIGN_OUT_ACTION]: async ({ commit }) => {
    try {
      await lowcoApi.logout();
    } catch {
      // Ignore error
    } finally {
      commit(types.SIGN_OUT);
    }
  },
};

const getters = {
  [types.USER_IS_CONNECTED_GETTER]: (state) => !!state[types.TOKEN],
  [types.USER_ID_GETTER]: (state) => {
    try {
      const { profileId: id } = jwtDecode(state[types.TOKEN]);

      return id;
    } catch {
      return null;
    }
  },
  [types.PROFILE_ID_GETTER]: (state) => {
    try {
      const { profileId } = jwtDecode(state[types.TOKEN]);

      return profileId;
    } catch (err) {
      return null;
    }
  },
  [types.USER_NAME_GETTER]: (state) => {
    try {
      const { unique_name: name } = jwtDecode(state[types.TOKEN]);

      return name;
    } catch {
      return null;
    }
  },
  [types.USER_ROLE_GETTER]: (state) => {
    try {
      const { role } = jwtDecode(state[types.TOKEN]);

      return role;
    } catch {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
