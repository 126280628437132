/* eslint-disable no-shadow */
import lowcoApi from '../../api/lowco-api';
import types from '../types/company';

const state = {
  [types.COMPANY_ID]: null,
  [types.COMPANY_ALL]: [],
  [types.COMPANY_IS_LOADING]: false,
};

const mutations = {
  [types.MUTATION_SET_ID]: (state, id) => {
    state[types.COMPANY_ID] = id;
  },
  [types.MUTATION_CLEAR]: (state) => {
    state[types.COMPANY_ID] = null;
    state[types.COMPANY_ALL] = [];
  },
  [types.MUTATION_SET_ALL]: (state, companies) => {
    state[types.COMPANY_ALL] = companies;
  },
  [types.MUTATION_SET_LOADING]: (state, value) => {
    state[types.COMPANY_IS_LOADING] = value;
  },
};

const actions = {
  [types.ACTION_GET_ALL_ADMIN]: async ({ commit }) => {
    commit(types.MUTATION_SET_LOADING, true);

    try {
      const result = await lowcoApi.getEnterprises();

      commit(types.MUTATION_SET_ALL, result);
    } catch (err) {
      throw new Error(err);
    } finally {
      commit(types.MUTATION_SET_LOADING, false);
    }
  },
  [types.ACTION_GET_ALL_USER]: async ({ commit }, userId) => {
    commit(types.MUTATION_SET_LOADING, true);

    try {
      const result = await lowcoApi.getUserCompanies(userId);

      commit(types.MUTATION_SET_ALL, result);
    } catch (err) {
      throw new Error(err);
    } finally {
      commit(types.MUTATION_SET_LOADING, false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
