import axios from 'axios';

import config from '@/config';

let internalStore;

const revokeCookies = () => axios.post(`${config.lowcoApiUrl}/auth/logout`);

const createHeaders = (headers, contentType) => {
  const httpHeaders = {
    ...contentType,
    'Accept-Language': 'fr',
  };

  if (headers) {
    return {
      ...httpHeaders,
      ...headers,
    };
  }

  return httpHeaders;
};

const exec = async (
  endpoint,
  method,
  headers,
  body,
) => {
  let response;
  try {
    response = await axios({
      method,
      url: endpoint,
      headers,
      data: body,
      withCredentials: true,
    });
  } catch (err) {
    const error = [{ code: err.response.status }];
    throw error;
  }

  if (response.status === 401) {
    revokeCookies();

    const authError = [{ code: '401' }];
    throw authError;
  }

  const result = await response.data;

  if (!result) {
    return '';
  }

  if (!result.success) {
    if (response.errors) {
      throw result.errors;
    }

    if (result) {
      return result;
    }

    const error = [{ code: result.status.toString(10) }];
    throw error;
  }

  return result.data;
};

const execJson = (
  endpoint,
  method,
  headers,
  body,
) => exec(
  endpoint,
  method,
  createHeaders(headers, { 'Content-Type': 'application/json' }),
  body,
);

const execFormData = (
  endpoint,
  method,
  headers,
  body,
) => exec(
  endpoint,
  method,
  createHeaders(headers, { 'Content-Type': 'multipart/form-data' }),
  body,
);

const get = (endpoint, headers) => execJson(endpoint, 'get', headers);

const post = (endpoint, body, headers) => execJson(endpoint, 'post', headers, body);

const postFormData = (endpoint, body, headers) => execFormData(endpoint, 'post', headers, body);

const put = (endpoint, body, headers) => execJson(endpoint, 'put', headers, body);

const remove = (endpoint, headers) => execJson(endpoint, 'delete', headers);

const init = (store) => {
  if (!internalStore) {
    internalStore = store;
  }
};

export default {
  get,
  post,
  postFormData,
  put,
  remove,
  init,
};
