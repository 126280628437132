import { createRouter, createWebHistory } from 'vue-router';

import cookiesUtils from '@/utils/cookies.utils';
import cookiesConstants from '@/constants/cookies.constants';
import rolesConstants from '@/constants/roles.constants';
import routesConstants from '@/constants/routes.constants';

import adminRoutes from './admin';

const { ADMINISTRATOR, BACKOFFICE } = rolesConstants;

const routes = [
  {
    path: '/',
    redirect: routesConstants.HOME,
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.HOME,
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.SIGN_IN,
    name: 'SignIn',
    component: () => import('../views/Login.vue'),
  },
  {
    path: routesConstants.SIGN_UP,
    name: 'SignUp',
    component: () => import('../views/Register.vue'),
  },
  {
    path: routesConstants.SIGN_UP_SUCCESS,
    name: 'SignUpSuccess',
    component: () => import('../views/RegisterSuccess.vue'),
  },
  {
    path: routesConstants.CONFIRM_EMAIL,
    name: 'ConfirmEmail',
    component: () => import('../views/PasswordLost.vue'),
  },
  {
    path: routesConstants.PASSWORD_LOST,
    name: 'PasswordLost',
    component: () => import('../views/PasswordLost.vue'),
  },
  {
    path: routesConstants.INVOICES,
    name: 'Invoices',
    component: () => import('../views/Invoices.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.PROFILE,
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.CATALOG,
    name: 'Catalog',
    component: () => import('../views/Catalog.vue'),
    beforeEnter() {
      window.location.href = 'https://my-dev.heureca.eu/';
    },
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.CGV,
    name: 'CGV',
    component: () => import('../views/CGV.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.NEW_ENTERPRISE,
    name: 'NewEnterprise',
    component: () => import('../views/CreateEnterprise.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  {
    path: routesConstants.OFFERS,
    name: 'Offers',
    component: () => import('../views/Offers.vue'),
    meta: {
      requiresAuth: [ADMINISTRATOR, BACKOFFICE],
    },
  },
  ...adminRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isConnected = !!cookiesUtils.getCookies(cookiesConstants.USER_INFOS);

  const canNavigate = to.matched.some((record) => {
    if (!record.meta.requiresAuth) {
      return true;
    }

    return cookiesUtils.isInRole(record.meta.requiresAuth);
  });

  if (canNavigate) {
    next();

    return;
  }

  if (!isConnected) {
    next(routesConstants.SIGN_IN);

    return;
  }

  next(routesConstants.HOME);
});

export default router;
