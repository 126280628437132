import { createStore, createLogger } from 'vuex';
import VuexPersist from 'vuex-persist';

import auth from './module/auth';
import company from './module/company';
import types from './types';

const vueLocalStorage = new VuexPersist({
  storage: window.localStorage,
  reducer: (state) => ({
    company: {
      id: state.company[types.COMPANY_ID],
    },
  }),
});

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    company,
  },
  plugins: process.env.NODE_ENV === 'production'
    ? [vueLocalStorage.plugin]
    : [vueLocalStorage.plugin, createLogger()],
});
