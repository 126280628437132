const HOME = '/home';
const SIGN_IN = '/signIn';
const SIGN_UP = '/signUp';
const SIGN_UP_SUCCESS = '/signUp/success';
const CONFIRM_EMAIL = '/confirm-email';
const PASSWORD_LOST = '/password-lost';
const INVOICES = '/invoices';
const PROFILE = '/profile';
const CATALOG = '/catalog';
const CGV = '/cgv';
const NEW_ENTERPRISE = '/profile/new-enterprise';
const ADMIN_ENTERPRISES = '/admin/enterprises';
const OFFERS = '/offers';

export default {
  HOME,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  CONFIRM_EMAIL,
  PASSWORD_LOST,
  INVOICES,
  PROFILE,
  CATALOG,
  CGV,
  NEW_ENTERPRISE,
  ADMIN_ENTERPRISES,
  OFFERS,
};
