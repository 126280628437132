// Namespace
const COMPANY_NAMESPACE = 'company';

// States
const COMPANY_ID = 'id';
const COMPANY_ALL = 'all';
const COMPANY_IS_LOADING = 'isLoading';

// State mutations
const MUTATION_SET_ID = 'setId';
const MUTATION_CLEAR = 'clearCompany';
const MUTATION_SET_ALL = 'setAll';
const MUTATION_SET_LOADING = 'setLoading';

// Actions
const ACTION_GET_ALL_ADMIN = 'getAllAdmin';
const ACTION_GET_ALL_USER = 'getAllUser';

export default {
  COMPANY_NAMESPACE,
  COMPANY_ID,
  COMPANY_ALL,
  COMPANY_IS_LOADING,
  MUTATION_SET_ID,
  MUTATION_CLEAR,
  MUTATION_SET_ALL,
  MUTATION_SET_LOADING,
  ACTION_GET_ALL_ADMIN,
  ACTION_GET_ALL_USER,
};
