const makeThrowError = (errorMapper) => (error) => {
  if (error?.errors?.length) {
    const errors = error.errors.map(errorMapper);
    throw errors;
  }

  const errors = ['Une erreur s\'est produite. Veuillez réessayer ultérieurement'];
  throw errors;
};

export default {
  makeThrowError,
};
