/* eslint-disable no-param-reassign */
import { createApp } from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue';
import Cleave from 'cleave.js';
import { createI18n } from 'vue-i18n';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

/* General style for all Lowco applications */
import '@/assets/styles/common/animation.scss';
import '@/assets/styles/common/elements.scss';
import '@/assets/styles/common/global.scss';
import '@/assets/styles/common/layout.scss';
import '@/assets/styles/common/normalize.scss';
import '@/assets/styles/common/ios-pwa-viewport.scss';
import '@/assets/styles/lowco.scss';

/* Components style for HTML elements */
import '@/assets/styles/components/input.scss';
import '@/assets/styles/components/checkbox.scss';
import '@/assets/styles/components/radio.scss';

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

import 'leaflet/dist/leaflet-src';
import 'leaflet.markercluster/dist/leaflet.markercluster-src';

import { languages, defaultLocale } from './translations';
import httpService from './services/http.service';
import './registerServiceWorker';

httpService.init(store);

const messages = Object.assign(languages);

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'fr',
  messages,
});

createApp(App)
  .use(CKEditor)
  .use(store)
  .use(router)
  .use(i18n)
  .directive('cleave', {
    mounted: (el, binding) => {
      el.cleave = new Cleave(el, binding.value || {});
    },
    updated: (el) => {
      el.value = el.cleave.properties.result;
      const event = new Event('input', { bubbles: true });
      el.dispatchEvent(event);
    },
  })
  .mount('#app');
