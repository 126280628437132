import auth from './types/auth';
import company from './types/company';

export default {
  TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken',
  AUTH_ERROR: 'authError',
  ...auth,
  ...company,
};
