const getEnvVariablesValues = (env, envVariablesNames) => envVariablesNames
  .map((name) => {
    if (!env[name]) {
      throw new Error(`${name} does not exist`);
    }

    return env[name];
  });

const buildConfig = () => {
  const [
    appAuthority,
    clientId,
    appHost,
    lowcoApiUrl,
    nominatimApiUrl,
    postLogoutRedirect,
    responseType,
    appScope,
    recaptchaPublicKey,
  ] = getEnvVariablesValues(
    process.env,
    [
      'VUE_APP_AUTHORITY',
      'VUE_APP_CLIENT_ID',
      'VUE_APP_HOST',
      'VUE_APP_LOWCO_API_URL',
      'VUE_APP_NOMINATIM_URL',
      'VUE_APP_POST_LOGOUT_REDIRECT',
      'VUE_APP_RESPONSE_TYPE',
      'VUE_APP_SCOPE',
      'VUE_APP_RECAPTCHA_PUBLIC_KEY',
    ],
  );

  return {
    appAuthority,
    clientId,
    appHost,
    lowcoApiUrl,
    nominatimApiUrl,
    postLogoutRedirect,
    responseType,
    appScope,
    recaptchaPublicKey,
  };
};

export default buildConfig();
