import config from '@/config';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    default:
      return 'Une erreur s\'est produite. Veuillez réessayer ultérieurement';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

// *** AUTH RELATED CALLS *** //

const authenticate = async (credentials) => httpService
  .post(`${BASE_URL}/auth/login`, credentials)
  .then((response) => response.data);

const logout = async () => httpService
  .post(`${BASE_URL}/auth/logout`, {})
  .then((response) => response.data);

const signUp = async (companyInfo) => httpService
  .post(`${BASE_URL}/accounts/companies/sign-up`, companyInfo)
  .then((response) => response.data);

const setPassword = async (userInfo) => httpService
  .post(`${BASE_URL}/accounts/password`, userInfo)
  .then((response) => response.data);

const resetPassword = async (userInfo) => httpService
  .put(`${BASE_URL}/accounts/password/lost`, userInfo)
  .then((response) => response.data);

const lostPassword = async (email) => httpService
  .post(`${BASE_URL}/accounts/password/lost`, { email })
  .then((response) => response.data);

// *** ANALYTICS RELATED CALLS *** //
const getCompaniesPageViews = () => httpService
  .get(`${BASE_URL}/analytics/page-views?pageSize=2000`)
  .catch(throwError);

const getCompanyPageViews = (companyId) => httpService
  .get(`${BASE_URL}/analytics/page-views/companies/${companyId}`)
  .catch(throwError);

const getProductsViews = (companyId) => httpService
  .get(`${BASE_URL}/analytics/page-views/companies/${companyId}/products?pageSize=2000`)
  .catch(throwError);

// *** ADMIN RELATED CALLS *** //

const getEnterprises = async (isActive = true) => httpService
  .get(`${BASE_URL}/admin/companies?isActiveCompany=${isActive}`)
  .catch(throwError);

const updateCompanyStatus = async (companyId, newStatus) => httpService
  .put(`${BASE_URL}/admin/companies/${companyId}/statuses?companyStatus=${newStatus}`, {})
  .catch(throwError);

// *** COMPANY RELATED CALLS *** //

const getCompaniesByName = async (name) => httpService
  .get(`${BASE_URL}/accounts/companies?name=${name}`)
  .catch(throwError);

const getUserCompanies = async (userId) => httpService
  .get(`${BASE_URL}/profiles/${userId}/companies`)
  .catch(throwError);

const getMyEnterprise = async (enterpriseId) => httpService
  .get(`${BASE_URL}/companies/${enterpriseId}`)
  .catch(throwError);

const getProducts = async (companyId) => httpService
  .get(`${BASE_URL}/companies/${companyId}/products?pageSize=200`)
  .catch(throwError);

const addCompanyToUser = async (company, profileId) => httpService
  .post(`${BASE_URL}/companies`, { ...company, profileId })
  .catch(throwError);

const addVideoToCompany = async (companyId, video) => httpService
  .post(`${BASE_URL}/companies/${companyId}/videos`, video)
  .catch(throwError);

const addImageToCompany = async (companyId, image) => httpService
  .postFormData(`${BASE_URL}/companies/${companyId}/images`, image)
  .catch(throwError);

const updateCompany = async (company) => httpService
  .put(`${BASE_URL}/companies`, company)
  .catch(throwError);

const removeCompanyMedia = async (companyId, mediaId) => httpService
  .remove(`${BASE_URL}/companies/${companyId}/medias/${mediaId}`);

// *** COMPANY CATEGORIES RELATED CALLS *** //

const getCompanyCategories = async () => httpService
  .get(`${BASE_URL}/company-categories`)
  .catch(throwError);

const getCategoryActivities = async (categoryId) => httpService
  .get(`${BASE_URL}/company-categories/${categoryId}/activities`)
  .catch(throwError);

// *** PRODUCT CATEGORIES RELATED CALLS *** //

const getProductCategories = () => httpService
  .get(`${BASE_URL}/product-categories`)
  .catch(throwError);

const addCategory = async (category) => httpService
  .post(`${BASE_URL}/product-categories`, category)
  .catch(throwError);

const updateCategory = async (category) => httpService
  .put(`${BASE_URL}/product-categories`, category)
  .catch(throwError);

const deleteCategory = async (categoryId) => httpService
  .remove(`${BASE_URL}/product-categories/${categoryId}`)
  .catch(throwError);

// *** CATALOG RELATED CALLS *** //

const getCategories = async (enterpriseId) => httpService
  .get(`${BASE_URL}/catalogue/categories/${enterpriseId}`)
  .catch(throwError);

const saveCategory = async (enterpriseId, category) => httpService
  .put(`${BASE_URL}/catalogue/categories/${enterpriseId}`, category)
  .catch(throwError);

// *** PRODUCTS RELATED CALLS *** //

const updateProduct = async (product) => httpService
  .put(`${BASE_URL}/products`, product)
  .catch(throwError);

const addProduct = async (product) => httpService
  .post(`${BASE_URL}/products`, product)
  .catch(throwError);

const addVideoToProduct = async (productId, video) => httpService
  .post(`${BASE_URL}/products/${productId}/videos`, video)
  .catch(throwError);

const addImageToProduct = async (productId, image) => httpService
  .postFormData(`${BASE_URL}/products/${productId}/images`, image)
  .catch(throwError);

const deleteProduct = async (productId) => httpService
  .remove(`${BASE_URL}/products/${productId}`)
  .catch(throwError);

const removeProductMedia = async (productId, mediaId) => httpService
  .remove(`${BASE_URL}/products/${productId}/medias/${mediaId}`)
  .catch(throwError);

// *** INVOICE RELATED CALLS *** //

const getInvoices = async (enterpriseId) => httpService
  .get(`${BASE_URL}/invoice/${enterpriseId}`)
  .catch(throwError);

const getInvoicePdf = async (code) => httpService
  .get(`${BASE_URL}/invoice/download/${code}`, { responseType: 'blob' })
  .catch(throwError);

// *** SUBSCRIPTION RELATED CALLS *** //

const getSubscriptions = async () => httpService
  .get(`${BASE_URL}/subscription/lowco`)
  .catch(throwError);

const subscribeMyEnterprise = async (enterprise) => httpService
  .post(`${BASE_URL}/subscription/my`, enterprise)
  .catch(throwError);

export default {
  authenticate,
  logout,
  signUp,
  setPassword,
  lostPassword,
  resetPassword,
  getUserCompanies,
  updateCompany,
  getInvoices,
  getInvoicePdf,
  getMyEnterprise,
  getSubscriptions,
  subscribeMyEnterprise,
  getCategories,
  saveCategory,
  getProducts,
  addCompanyToUser,
  updateProduct,
  getProductCategories,
  getCompanyCategories,
  addProduct,
  addCategory,
  updateCategory,
  deleteCategory,
  deleteProduct,
  addImageToProduct,
  getEnterprises,
  addImageToCompany,
  updateCompanyStatus,
  removeCompanyMedia,
  getCategoryActivities,
  removeProductMedia,
  addVideoToCompany,
  addVideoToProduct,
  getCompanyPageViews,
  getCompaniesPageViews,
  getProductsViews,
  getCompaniesByName,
};
