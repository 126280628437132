const AUTH_NAMESPACE = 'auth';
const SIGN_IN = 'signIn';
const SIGN_OUT = 'signOut';
const SIGN_IN_SUCCESS = 'signInSuccess';
const SIGN_IN_FAILED = 'signInFailed';
const SIGN_IN_ACTION = 'signInAction';
const SIGN_OUT_ACTION = 'signOutAction';

const USER_ID_GETTER = 'getUserId';
const PROFILE_ID_GETTER = 'getProfileId';
const USER_NAME_GETTER = 'getUserName';
const USER_ROLE_GETTER = 'getUserRole';
const USER_IS_CONNECTED_GETTER = 'isUserConnected';

export default {
  AUTH_NAMESPACE,
  SIGN_IN,
  SIGN_OUT,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILED,
  SIGN_IN_ACTION,
  SIGN_OUT_ACTION,
  USER_ID_GETTER,
  PROFILE_ID_GETTER,
  USER_NAME_GETTER,
  USER_ROLE_GETTER,
  USER_IS_CONNECTED_GETTER,
};
